import React from "react";

function Menu() {
    return (
        <div className="sticky top-2 z-50 mt-2 flex shrink-0 flex-col items-center justify-center">
            <div className="fi-lato box-border flex h-16 w-4/5 min-w-screenWidth flex-row items-center rounded-xl bg-red-800 p-2 text-white shadow-black drop-shadow-lg">
                <div className="flex grow items-center justify-start">
                    <i className="fa fa-chef-ss-symbolicons-block fa-3x px-4 text-4xl" />
                    <h1 className="text-3xl font-semibold">My Recipes</h1>
                </div>
                <div className="flex shrink-0 flex-row items-center">
                    <input
                        type="text"
                        className="h-8 w-60 rounded-xl bg-red-100 p-4 font-bold text-zinc-700 opacity-50 shadow-inner shadow-zinc-700 placeholder:text-center placeholder:text-zinc-700 focus:outline-none"
                        placeholder="Name or ingredient"
                    />
                    <i className="fa fa-search fa-2x pr-2 pl-4" />
                </div>
            </div>
        </div>
    );
}

export default Menu;
