import React from "react";
import Menu from "./components/Menu";
import RecipeList from "./components/RecipeList";
import { results } from "./data";

function App() {
    return (
        <div>
            <Menu />
            <RecipeList recipeList={results} />
        </div>
    );
}

export default App;
