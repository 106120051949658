import React from "react";
import Recipe, { RecipeProps } from "./Recipe";

function RecipeList({ recipeList }: RecipeListProps) {
    return (
        <div className="flex shrink-0 grow flex-col flex-wrap items-center justify-center">
            <div className="mt-2 flex w-4/5 min-w-screenWidth flex-row flex-wrap justify-start gap-4 overflow-auto rounded-xl bg-white p-2">
                {recipeList.map(({ picture, title, description, recipeDetails }: RecipeProps, index) => (
                    <Recipe key={index} title={title} description={description} picture={picture} />
                ))}
            </div>
        </div>
    );
}

export interface RecipeListProps {
    recipeList: RecipeProps[];
}

export default RecipeList;
