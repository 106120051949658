import React from "react";
import { RecipeDetailsProps } from "./RecipeDetails";

function Recipe({ picture, title, description }: RecipeProps) {
    return (
        <div className="fi-lato flex h-cardHeight w-cardWidth flex-col overflow-hidden rounded-xl text-white shadow-black drop-shadow-lg">
            <div className="flex h-cardHeaderHeight min-h-cardHeaderHeight flex-col justify-center rounded-t-xl bg-emerald-600 p-2 text-lg font-semibold">
                {title}
            </div>
            <div className="max-h-cardContentHeight max-w-cardWidth grow bg-emerald-100 p-2">
                <img className="float-left max-h-recipeImage max-w-recipeImage pr-2" src={picture} alt="recipe" />
                <p className="p-2 text-left italic text-emerald-900">{description}</p>
            </div>
            <div className="flex h-cardBottomHeight flex-row items-center justify-end rounded-b-xl bg-emerald-100 p-2 text-emerald-800">
                <i className="fa fa-star px-1 text-3xl"></i>
                <i className="fa fa-write-ss-symbolicons-block px-1 text-3xl"></i>
                <i className="fa fa-trash px-1 text-3xl"></i>
            </div>
        </div>
    );
}

export interface RecipeProps {
    picture?: string;
    title: string;
    description?: string;
    recipeDetails?: RecipeDetailsProps;
    ingredients?: RecipeIngredientProps[];
    steps?: string[];
}

export interface RecipeIngredientProps {
    amount?: number;
    unit?: string;
    description: string;
}

export default Recipe;
