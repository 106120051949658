import { RecipeProps } from "./components/Recipe";

export const results: RecipeProps[] = [
    {
        picture: "default.png",
        title: "Frittata van kersentomaatjes, ricotta en serranoham",
        description:
            "Italiaans ei gerecht. Heerlijk om de dag mee te beginnen. Is ook zeer geschikt om tijdens de lunch te nuttigen. ",
        recipeDetails: {
            kcal: 800,
            prepTime: 60,
            cookTime: 45,
        },
        ingredients: [
            {
                amount: 150,
                unit: "gr",
                description: "cherrytomaatjes door de helft gesneden",
            },
            {
                amount: 6,
                description: "eieren",
            },
            {
                amount: 6,
                unit: "el",
                description: "slagroom",
            },
            {
                amount: 3,
                unit: "el",
                description: "gemalen Parmezaanse kaas",
            },
            {
                amount: 2,
                unit: "el",
                description: "olijfolie",
            },
            {
                amount: 2,
                unit: "el",
                description: "basilicum blaadjes",
            },
            {
                description: "zout",
            },
            {
                description: "peper",
            },
            {
                amount: 1,
                description: "ui in dunne ringen gesneden",
            },
            {
                amount: 6,
                unit: "plakjes",
                description: "serranoham",
            },
            {
                amount: 100,
                unit: "gr",
                description: "ricotta",
            },
        ],
        steps: [
            "Halveer de tomaatjes en bestrooi ze met een beetje zout en peper en laat dit 5 minuten intrekken.",
            "Doe de eieren, room, Parmezaanse kaas en wat peper in een kom en klop dit  met een garde door.",
            "Verhit de olie in een koekenpan en bak de uien op laag vuur kort aan.",
            "Giet het ei mengsel in de pan en leg hierop de tomaatjes, ricotta en de serranoham.",
            "Laat op een zacht vuur het mengsel in +/- 15 minuten gaar worden (gebruik evt een deksel op de pan).",
            "Haal de frittata uit de pan en leg deze op een schaal.",
            "Garneer al met basilicum .",
        ],
    },
    {
        picture: "default_wide.png",
        title: "Melansane a la whatever",
        description:
            "Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos.",
    },
    {
        picture: "default_wide.png",
        title: "Melansane a la whatever",
        description:
            "Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos.",
    },
    {
        picture: "default_wide.png",
        title: "Melansane a la whatever",
        description:
            "Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos.",
    },
    {
        picture: "default_wide.png",
        title: "Melansane a la whatever",
        description:
            "Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos.",
    },
    {
        picture: "default_wide.png",
        title: "Melansane a la whatever",
        description:
            "Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos.",
    },
    {
        picture: "default_wide.png",
        title: "Melansane a la whatever",
        description:
            "Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos.",
    },
    {
        picture: "default_wide.png",
        title: "Melansane a la whatever",
        description:
            "Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos.",
    },
    {
        picture: "default_wide.png",
        title: "Melansane a la whatever",
        description:
            "Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos.",
    },
];
